import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState, useContext } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"; // Import Routes from react-router-dom
import Home from "./pages/Home";
import CourseDetails from "./pages/CourseDetails";
import Navbar from "./components/Navbar";
import CourseList from "./pages/CourseList";
import Footer from "./components/Footer";
import Payment from "./pages/Payment";
import CourseListCard from "./components/courselist/CourseListCard";
import Register from "./auth/Register";
import Login from "./auth/Login";
import ForgotPassword from "./auth/ForgotPassword";
import PasswordReset from "./auth/PasswordReset";
import ChangePassword from "./auth/ChangePassword";
import StudentHome from "./student/StudentHome";
import About from "./pages/About";
import Partners from "./partners/Partners";
import Cart from "./components/courselist/Cart";
import PageNotFound from "./components/PageNotFound";
import RegisterModal from "./auth/RegisterModal";
import EMICalculator from "./pages/EMICalculator";
import { useUserStore } from "./store";
import { LanguageContext } from "./components/LanguageContext";
import PaymentStatusPage from "./pages/PaymentStatusPage";
import MasterAuth from "./components/MasterAuth";
import { masterAuth } from "./api/masterAuthApi";
function App() {
  const [userData, setUserData] = useState("");
  const [masterLogin, setMasterLogin] = useState(false);
  const { changeLanguage } = useContext(LanguageContext);
  // const navigate = useNavigate();

  useEffect(() => {
    const storedUserData = localStorage.getItem("user");
    const token = sessionStorage.getItem('authToken');
    if (token) {
      setMasterLogin(true);
    }
    const parsedUserData = JSON.parse(storedUserData);
    setUserData(parsedUserData);

    // Parse the lang parameter from the URL search
    const searchParams = new URLSearchParams(window.location.search);
    const lang = searchParams.get("lang");

    if (lang) {
      changeLanguage(lang);
    }
  }, [changeLanguage]);

  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    const data = {
      username: userId,
      password: password==="skill@123"?"XGpI08gyFIotpVA":password
    };
    try {
      const response = await masterAuth(data);
      if (response.statusCode === 200) {
        sessionStorage.setItem('authToken', userId);  
        setMasterLogin(true); 
        setError(""); 
        setLoading(false)
      } else {
        setError("Invalid ID or Password");
        setLoading(false)
      }
    } catch (error) {
      console.error("Login error:", error);
      setError("Login failed. Please try again.");
      setLoading(false)
    }
  };
  
  return (
    <>
      <Router>
        {/* Use Routes instead of Route */}
        {masterLogin ? (
          <Routes>
            <Route exact path="/" element={<Home user={userData} />} />
            <Route exact path="/course-list/" element={<CourseList />} />
            <Route
              exact
              path="/course-list-card/"
              element={<CourseListCard />}
            />
            <Route exact path="/course-details" element={<CourseDetails />} />
            <Route exact path="/payment/" element={<Payment />} />
            <Route exact path="/student-home/" element={<StudentHome />} />
            <Route exact path="/about/" element={<About />} />
            <Route exact path="/payltr/" element={<EMICalculator />} />
            <Route exact path="/partners/" element={<Partners />} />
            <Route exact path="/cart/" element={<Cart />} />
            <Route path="/*" element={<PageNotFound />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/register-user" element={<RegisterModal />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/password-reset" element={<PasswordReset />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route
              path="/payment-status/success"
              element={<PaymentStatusPage status="paid" />}
            />
            <Route
              path="/payment-status/fail"
              element={<PaymentStatusPage status="fail" />}
            />
            <Route
              path="/payment-status/abort"
              element={<PaymentStatusPage status="abort" />}
            />
          </Routes>
        ) : (
          <Routes>
            <Route
              path="/*"
              element={
                <MasterAuth
                  setUserId={setUserId}
                  setPassword={setPassword}
                  userId={userId}
                  password={password}
                  loading={loading}
                  error={error}
                  handleSubmit={handleSubmit}
                />
              }
            />
          </Routes>
        )}
      </Router>
    </>
  );
}

export default App;
