import React, { useState } from "react";
import { Container, Row, Col, Button, Form, Alert } from "react-bootstrap";

const MasterAuth = ({ setUserId, setPassword, error, loading, handleSubmit, password, userId }) => {

  //   if (isAuthenticated) {
  //     return (
  //       <Container className="mt-5 text-center">
  //         <h1>Welcome to the Protected Content!</h1>
  //         <p>You have successfully logged in.</p>
  //       </Container>
  //     );
  //   }

  return (
    <Container style={{
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    }}>
      <div style={{ border: "1px solid #d6d7dd", padding: "20px", borderRadius: "10px" }}>
        <h3 className="text-center">Login To Access Your Website</h3>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={12}>
              <Form.Group controlId="formUserId">
                <Form.Label>
                 <strong> User ID</strong> 
                   </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your User ID"
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>

            <Col md={12}>
              <Form.Group controlId="formPassword">
                <Form.Label><strong>Password</strong> </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter your Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>

            <Col>
              {loading ? <Button variant="primary" type="submit" className="mt-3" block>Loading...</Button> : <Button variant="primary" type="submit" className="mt-3" block>
                Submit
              </Button>}
            </Col>


          </Row>


        </Form>
      </div>

    </Container >
  );
};

export default MasterAuth;
