import React, { useState, useContext, useCallback } from "react";
import { Card, Row, Col, Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  format,
  isSameMonth,
  isSameDay,
  startOfMonth,
  endOfMonth,
} from "date-fns"; /* for date calculations*/
import "react-datepicker/dist/react-datepicker.css";
import "../assets/css/CourseList.css";
import axios from "axios";
import {
  categoryTag,
  subCertifiedTag,
  subNewTag,
  titleTag,
  subTopRatedTag,
} from "../assets/customcss/CustomCss";
import "../assets/css/Payments.css";
import {
  FiCheckCircle,
  FiStar,
  FiShoppingCart,
  FiCalendar,
  FiInfo,
  FiClock,
} from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import {
  useUserStore,
  useLocalStore,
  useCartStore,
  useCourseStore,
} from "../store";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../components/LanguageContext";
import PaymentStatusPage from "./PaymentStatusPage";
import { debounce } from "lodash";
import { baseUrl } from "../config";
import { userDataUpdate } from "../api/userApi";
import Payl8rCalculator from "../components/Payl8rCalculator";
import TruncateString from "../components/TruncateString";
const Payment = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const courseInfo = location.state;
  const languageData = useContext(LanguageContext);
  const { storeData, fetchLocalStorage } = useLocalStore();
  const { sigleCourse, courses, fetchCourses } = useCourseStore();
  const [courseData, setCourseData] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await fetchCourses();
      setCourseData(sigleCourse);
    }
    fetchData();
  }, [fetchCourses, sigleCourse]);

  const [showModal, setShowModal] = useState(true);
  const [userData, setUserData] = useState();
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [responseData, setReasponseData] = useState({});
  const [iFrameUrl, setIframeUrl] = useState("");
  const [registerdCourseId, setRegisterdCourseId] = useState("");
  const [dateToLocation, setDateToLocation] = useState(null);
  const [addressData, setAddressData] = useState(false);
  const [errorMsg, setError] = useState(false);
  const uniqueAddresses = new Set();
  const [formData, setFormData] = useState({
    address: "",
    city: "",
    pesel_number: "",
    email_id: "",
    first_name: null,
    last_name: null,
    // confirm_password: '',
    // post_code: "",
    age: null,
    country_code: "+44",
    mobile: null,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    const maxLength = 15;
    if (name === "mobile" && value?.length > maxLength) {
      return;
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setChecked(false);
  };

  const dobA = "";
  const countryA = "UK";

  const dateObject = new Date(courseInfo?.courseDetail?.courseStartsAt);
  const timestamp = dateObject.getTime();
  const [selectedDateId, setSelectedDate] = useState("");
  const [selectedDateData, setSelectedDateData] = useState("");

  const [selectedCourseDate, setSelectedCourseDate] = useState(
    courseInfo?.selectedCourse
  );
  const handleCourseReg = async (id) => {
    const data = {
      course: courseInfo?.courseDetail.id,
      course_location: parseInt(id),
    };

    try {
      const authToken = trimmedUserToken;
      const response = await axios.post(`${baseUrl}/course/user`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.data.data.data.id) {
        setRegisterdCourseId(response.data.data.data.id);
        return response;
      }
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      } else {
        console.log("Course registered successfully!");
      }
    } catch (error) {
      console.error("Error Registering User", error);
    }
  };
  const handleLocationChange = (item) => {
    const data = JSON.parse(item);
    let objectData = courseInfo?.courseDetail?.course_locations?.find(
      (item) => item.id == data?.id
    );
    const dateTo = objectData?.firm_addresses[0]?.id;
    setDateToLocation(dateTo);
    setSelectedLocation(dateTo);
    // handleCourseReg(data.id);
    setSelectedDateData(objectData);
  };
  const handleShowModal = () => {
    setShowModal(true);
  };
  const { items, addToCart, removeFromCart } = useCartStore();

  const userToken = Cookies.get("UUID");
  const trimmedUserToken = userToken?.trim();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${baseUrl}/user/me`, {
          headers: {
            Authorization: `Bearer ${trimmedUserToken}`,
          },
        });
        const data = await response.json();
        setUserData(data);
        setFormData((prevFormData) => ({
          ...prevFormData,
          first_name: data?.data?.first_name,
          last_name: data?.data?.last_name,
          email_id: data?.data?.email,
          pesel_number: data?.data?.pesel_number,
          mobile: data?.data?.mobile,
          city: data?.data?.city,
          address: data?.data?.address,
        }))
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchData();
  }, [trimmedUserToken]);

  const handleCloseModal = () => {
    setShowModal(false);
    navigate(-1);
  };
  /* Date Picker calculations */
  const handleDateChange = (data) => {
    const item = JSON.parse(data);
    setSelectedDate(item.id);
    setSelectedDateData(item);
    // handleCourseReg(item.id)
  };

  const isOutsideCurrentMonth = (date, currentDate) => {
    return !isSameMonth(date, currentDate);
  };

  const dateClassName = (date) => {
    if (isOutsideCurrentMonth(date, selectedDateId)) {
      return "text-muted"; // Apply the grey color for dates outside the current month
    }
    return "";
  };

  // useEffect(() => {
  //   const handleCourseReg = async () => {
  //     const data = {
  //       course: courseInfo?.courseDetail.id,
  //       course_location: parseInt(selectedCourseDate?.id),
  //     };

  //     try {
  //       const authToken = trimmedUserToken;
  //       const response = await axios.post(`${baseUrl}/course/user`, data, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${authToken}`,
  //         },
  //       });
  //       if (response.data.data.data.id) {
  //         setRegisterdCourseId(response.data.data.data.id);
  //       }
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       } else {
  //         console.log("Course registered successfully!");
  //       }
  //     } catch (error) {
  //       console.error("Error Registering User", error);
  //     }
  //   };

  //   if (selectedCourseDate) {
  //     const debouncedHandleCourseReg = debounce(handleCourseReg, 500);
  //     debouncedHandleCourseReg();
  //     return () => {
  //       debouncedHandleCourseReg.cancel();
  //     };
  //   }
  // }, [selectedCourseDate]);

  //  the total price
  const totalPrice = courseInfo?.courseDetail?.total_price;

  // Calculate the total price including the 6% fee
  const calculat = totalPrice ? totalPrice * 1.06 : 0;
  const totalPriceAndFee = calculat?.toFixed(2);

  const sendDataForIncription = async () => {
    setLoader(true);
    const data = {
      firstnames: formData?.first_name,
      surname: formData?.last_name,
      email: userData?.data?.email_id,
      phone: formData?.mobile,
      address: formData?.address,
      city: formData?.city,
      country: countryA,
      postcode: formData?.pesel_number,
      dob: dobA,
      delivery_address: formData?.address,
      delivery_city: formData?.city,
      delivery_postcode: formData?.pesel_number,
      delivery_country: countryA,
      price: totalPriceAndFee,
      order_id: registerdCourseId,
    };

    try {
      // const response = await axios.post('https://chatcloudserver.co.in/1.php', data);
      const response = await axios.post(
        `${baseUrl}/course/request_pg_keys`,
        data
      );
      if (response) {
        setReasponseData(response.data);
        setLoader(false);
      }
      // console.log("response for getting encryption data", response.data); // Corrected console log message
    } catch (error) {
      console.log("error during the encryption data", error);
      setLoader(false);
    }
  };

  const saveUserInfo = () => {
    if (formData?.address && formData?.city && formData?.pesel_number) {
      setAddressData(true);
      setError(false);
    } else {
      setAddressData(false);
      setError(true);
    }
  };

  const toastForCheckBox = () => {
    if (!selectedLocation && !selectedCourseDate) {
      toast.error("Please Choose Location..!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

    if (!checked) {
      toast.error("Please Accept Terms and Conditions..!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (!checked2) {
      toast.error("Please Accept Privacy Policy..!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    saveUserInfo();
  };

  const handleCheckboxChange = async (event) => {
    if (
      formData?.address &&
      formData?.city &&
      formData?.pesel_number &&
      selectedDateId &&
      formData.first_name &&
      formData.last_name &&
      formData.mobile
    ) {
      sendDataForIncription();
      setChecked(event?.target?.checked);
      if (event?.target?.checked) {
        try {
          const response = await handleCourseReg(selectedDateId);
          if (response.data.message === "Success") {
            console.log("Unbale to register course");
            // sendDataForIncription();
          } else {
            console.log("Unbale to register course");
          }
        } catch (error) {
          console.log("course not registered");
        }
      } else {
        console.log("course not registered");
      }

    } else {
      toast.error("Please Fill Address First..!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setAddressData(false);
      setError(true);
    }
  };
  const handleCheckboxChange1 = async (event) => {
    if (
      formData?.address &&
      formData?.city &&
      formData?.pesel_number &&
      selectedDateId &&
      formData.first_name &&
      formData.last_name &&
      formData.mobile
    ) {
      sendDataForIncription();
      setChecked2(event?.target?.checked);
      if (event?.target?.checked) {
        try {
          const response = await handleCourseReg(selectedDateId);
          if (response.data.message === "Success") {
            console.log("Unbale to register course");
            
          } else {
            console.log("Unbale to register course");
          }
        } catch (error) {
          console.log("course not registered");
        }
      } else {
        console.log("course not registered");
      }

    } else {
      toast.error("Please Fill Address First..!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setAddressData(false);
      setError(true);
    }
  };

  const saveAddressData = async () => {
    const response = await userDataUpdate(userData.data.id, formData);
  };

  const filterDate = courseInfo?.courseDetail?.course_locations.filter(
    (item) => item?.location_id === selectedLocation
  );

  const forAddresses = [];

  // Preprocess the course locations to filter out duplicates
  const filteredLocations = courseInfo?.courseDetail?.course_locations?.filter(
    (element) => {
      const address1 = element?.firm_addresses?.[0]?.address1;
      const address2 = element?.firm_addresses?.[0]?.address2;
      const postalCode = element?.firm_addresses?.[0]?.postal_code;

      // Construct the label for the address
      const label =
        address1 === "Virtual Classroom"
          ? "Virtual Classroom"
          : `${address1} ${address2} ${postalCode}`;

      // Check if the label is already in the forAddresses array
      if (!forAddresses.some((es) => es.label === label)) {
        // Add the address to forAddresses
        forAddresses.push({ id: element.id, label });
        return true;
      }

      return false;
    }
  );

  const Spinner = () => (
    <div>
      <div className="spinner-border" role="status" style={{ width: '1rem', height: '1rem', marginRight: '5px' }}>
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>

  );

  return (
    <div className="payment-container my-5">
      <ToastContainer
        position="top-center"
        theme="colored"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {iFrameUrl ? (
        <iframe
          srcDoc={iFrameUrl}
          title="Example website"
          style={{ width: "100%", height: 600 }}
        ></iframe>
      ) : (
        // <PaymentStatusPage isSuccess={true}></PaymentStatusPage>
        // <PaymentStatusPage status="return_data" />
        <Modal
          show={showModal}
          // onHide={handleCloseModal}
          dialogClassName="custom-payment-modal"
          className="payment-modal"
        /* style={{
display:'flex',
justifyContent:'center',
margin:'0px',
backgroundColor:'yellow',
}} */
        >
          <Modal.Header style={{ display: "none" }}>
            <Modal.Title>{/* Registration To The Course */}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0 payment-modal-body">
            {/* Place your payment form or content here */}


            <div className="">
              <div className="row payment-card-body">
                <div className="payment-modal-close">
                  <Button
                    onClick={handleCloseModal}
                  >
                    Close
                  </Button>
                </div>
                <div className="col-md-7 col-sm-12 payment-card-left">
                  <h3 className="py-0">
                    {t("Registration To The Course")}
                  </h3>
                  <h5
                    className="card-title"
                    style={{ marginBottom: "10px" }}
                  >
                    {t("Personal info")}
                  </h5>
                  <div className="payment-form-group row mt-3" style={{ position: "relative" }}>
                    <div className="col-6">
                      <label
                        htmlFor="name"
                        style={{
                          fontSize: "12px",
                          color: "grey",
                          // fontWeight: 700,
                        }}
                      >
                        {" "}
                        {t("First name")}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        value={formData.first_name}
                        name="first_name"
                        type="text"
                        className="form-control "
                        onChange={handleChange}
                        /*  style={{ width:'100%'}} */ id="name"
                      />
                      {!formData?.first_name && errorMsg && (
                        <span
                          style={{
                            color: "red",
                            fontSize: "12px",
                            fontWeight: "500",
                            position: "absolute",
                            top: "72px",
                          }}
                        >
                          This field is required..!{" "}
                        </span>
                      )}
                    </div>
                    <div className="col-6">
                      <label
                        htmlFor="name"
                        style={{
                          fontSize: "12px",
                          color: "grey",
                          // fontWeight: 700,
                        }}
                      >
                        {" "}
                        {t("Last name")}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        value={formData?.last_name}
                        name="last_name"
                        type="text"
                        className="form-control "
                        onChange={handleChange}
                        /* style={{ width:'45%' }} */ id="name"
                      />
                      {!formData?.last_name && errorMsg && (
                        <span
                          style={{
                            color: "red",
                            fontSize: "12px",
                            fontWeight: "500",
                            position: "absolute",
                            top: "72px",
                          }}
                        >
                          This field is required..!{" "}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="payment-form-group row mt-3" style={{ position: "relative" }}>
                    <div className="col-6">
                      <label
                        htmlFor="phone"
                        style={{
                          fontSize: "12px",
                          color: "grey",
                          // fontWeight: 700,
                        }}
                      >
                        {" "}
                        {t("Phone")} <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        value={formData?.mobile}
                        name="mobile"
                        type="number"
                        className="form-control"
                        onChange={handleChange}
                        id="phone"
                        maxLength={10}
                      />
                      {!formData?.mobile && errorMsg && (
                        <span
                          style={{
                            color: "red",
                            fontSize: "12px",
                            fontWeight: "500",
                            position: "absolute",
                            top: "72px",
                          }}
                        >
                          This field is required..!{" "}
                        </span>
                      )}
                    </div>
                    <div className="col-6">
                      <label
                        htmlFor="email"
                        style={{
                          fontSize: "12px",
                          color: "grey",
                          // fontWeight: 700,
                        }}
                      >
                        {" "}
                        {t("Email")}
                      </label>
                      <input
                        value={userData?.data?.email_id}
                        type="text"
                        className="form-control "
                        id="name"
                        disabled
                      />
                    </div>
                  </div>

                  <div className="payment-form-group mt-3" style={{ position: "relative" }}>
                    <div className="row align-items-center mt-3">
                      <label
                        htmlFor="address"
                        className="col-auto"
                        style={{
                          fontSize: "12px",
                          color: "grey",
                          // fontWeight: 700,
                        }}
                      >
                        {t("Address")}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <input
                      value={formData?.address}
                      name="address"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="phone"
                      maxLength={50}
                    />
                    {!formData?.address && errorMsg && (
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          fontWeight: "500",
                          position: "absolute",
                          top: "72px",
                        }}
                      >
                        This field is required..!{" "}
                      </span>
                    )}

                    {formData?.address?.length > 49 && (
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          fontWeight: "500",
                          position: "absolute",
                          top: "66px",
                        }}
                      >
                        Address should be less than 50 characters{" "}
                      </span>
                    )}
                  </div>
                  <div className="payment-form-group row mt-3" style={{ position: "relative" }}>
                    <div className="col-6">
                      <label
                        htmlFor="name"
                        style={{
                          fontSize: "12px",
                          color: "grey",
                          // fontWeight: 700,
                        }}
                      >
                        {" "}
                        {t("City")}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        name="city"
                        value={formData?.city}
                        onChange={handleChange}
                        type="text"
                        className="form-control "
                        id="name"
                      />
                      {!formData?.city && errorMsg && (
                        <span
                          style={{
                            color: "red",
                            fontSize: "12px",
                            fontWeight: "500",
                            position: "absolute",
                            top: "72px",
                          }}
                        >
                          This field is required..!{" "}
                        </span>
                      )}
                    </div>
                    <div className="col-6">
                      <label
                        htmlFor="name"
                        style={{
                          fontSize: "12px",
                          color: "grey",
                          // fontWeight: 700,
                        }}
                      >
                        {" "}
                        {t("Postal Code")}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        name="pesel_number"
                        value={formData?.pesel_number}
                        onChange={handleChange}
                        type="text"
                        className="form-control "
                        id="name"
                      />
                      {!formData?.pesel_number && errorMsg && (
                        <span
                          style={{
                            color: "red",
                            fontSize: "12px",
                            fontWeight: "500",
                            position: "absolute",
                            top: "72px",
                          }}
                        >
                          This field is required..!{" "}
                        </span>
                      )}
                    </div>
                  </div>
                  <div style={{ marginTop: "30px" }}>
                    {" "}
                    <h5 className="my-1">{t("Course details")}</h5>
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="payment-form-group">
                        <label
                          htmlFor="location"
                          style={{
                            fontSize: "12px",
                            color: "grey",
                            // fontWeight: 700,
                          }}
                        >
                          {t("Location")}{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        {selectedCourseDate ? (
                          <input
                            value={
                              selectedCourseDate?.locations &&
                              selectedCourseDate?.locations[0]?.name
                            }
                            type="text"
                            className="form-control"
                            id="phone"
                          />
                        ) : (
                          <select
                            defaultValue=""
                            className="form-control"
                            id="location "
                            // value={selectedLocation}
                            onChange={(e) => {
                              setSelectedLocation(e.target.value);
                              handleLocationChange(e.target.value);
                            }}
                          >
                            {/* Options for locations */}
                            <option value="" disabled>
                              Choose Location
                            </option>
                            {filteredLocations?.map((loc) => {
                              const address1 =
                                loc?.firm_addresses?.[0]?.address1;
                              const address2 =
                                loc?.firm_addresses?.[0]?.address2;
                              const postalCode =
                                loc?.firm_addresses?.[0]?.postal_code;

                              // Construct the display address again for rendering
                              const displayAddress =
                                address1 === "Virtual Classroom"
                                  ? "Virtual Classroom"
                                  : `${address1} ${address2} ${postalCode}`;

                              return (
                                <option
                                  key={loc.id}
                                  value={JSON.stringify(loc)}
                                >
                                  {displayAddress}
                                </option>
                              );
                            })}
                            {/* {courseInfo?.courseDetail?.course_locations?.map(
                              (loc) => (
                                <option
                                  key={loc.id}
                                  value={JSON.stringify(loc)}
                                >
                                  {loc?.firm_addresses &&
                                  loc.firm_addresses[0]?.address1 ===
                                    "Virtual Classroom"
                                    ? loc.firm_addresses[0]?.address1
                                    : loc.firm_addresses[0]?.address1 +
                                      " " +
                                      loc.firm_addresses[0]?.address2 +
                                      " " +
                                      loc.firm_addresses[0]?.postal_code}
                                </option>
                              )
                            )} */}
                            {/* <option value="Location 1">{location.state.courseDetail.course_locations}</option>
                    <option value="Location 2">Location 2</option> */}
                          </select>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="payment-form-group row mt-3" style={{ position: "relative" }}>
                    <div className="col-6">
                      <div className="payment-form-group">
                        <label
                          htmlFor="date"
                          style={{
                            fontSize: "12px",
                            color: "grey",
                            // fontWeight: 700,
                          }}
                        >
                          {t("Select Date")}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          defaultValue=""
                          className="form-control"
                          id="location "
                          onChange={(e) => {
                            handleDateChange(e.target.value);
                          }}
                          disabled={filterDate?.length === 0}
                        >
                          <option value="" disabled>
                            Choose Date
                          </option>
                          {/* {filterDate?.map((item) => (
                            <option key={item.id} value={JSON.stringify(item)}>
                              {item.date}
                            </option>
                          ))} */}
                          {filterDate?.filter((item) => {
                              const itemDate = new Date(item.date);
                              const today = new Date();
                              today.setHours(0, 0, 0, 0);
                              return itemDate >= today;
                            })
                            .map((item) => (
                              <option key={item.id} value={JSON.stringify(item)}>
                                {item.date}
                              </option>
                            ))}
                        </select>
                        {!selectedDateId && errorMsg && (
                          <span
                            style={{
                              color: "red",
                              fontSize: "12px",
                              fontWeight: "500",
                              position: "absolute",
                              top: "72px",
                            }}
                          >
                            This field is required..!{" "}
                          </span>
                        )}
                      </div>
                      {/* <div className="payment-form-group">
                        <label
                          className="col-9"
                          htmlFor="date"
                          style={{
                            fontSize: "12px",
                            color: "grey",
                            fontWeight: 700,
                          }}
                        >
                          {" "}
                          {t("Date")}
                        </label>
                   
                        {selectedCourseDate ? (
                          <input
                            value={selectedCourseDate?.date}
                            type="text"
                            disabled
                            className="form-control col-9"
                          />
                        ) : (
                          <input
                            value={dateToLocation}
                            type="text"
                            disabled
                            className="form-control col-9"
                          />
                        )}
                      </div> */}
                    </div>
                    <div className="col-6">
                      <label
                        htmlFor="phone"
                        style={{
                          fontSize: "12px",
                          color: "grey",
                          // fontWeight: 700,
                        }}
                      >
                        {" "}
                        {t("Duration")}{" "}
                      </label>
                      <input
                        value={selectedDateData?.duration_number}
                        type="text"
                        className="form-control"
                        id="phone"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="payment-form-group row mt-3">
                    <div className="col-6">
                      <label
                        htmlFor="phone"
                        style={{
                          fontSize: "12px",
                          color: "grey",
                          // fontWeight: 700,
                        }}
                      >
                        {" "}
                        {t("Duration Type")}{" "}
                      </label>
                      <input
                        value={
                          selectedDateData?.duration_type
                            ? selectedDateData.duration_type
                              .charAt(0)
                              .toUpperCase() +
                            selectedDateData?.duration_type.slice(1)
                            : ""
                        }
                        type="text"
                        className="form-control"
                        id="phone"
                        disabled
                      />
                    </div>
                    <div className="col-6">
                      <label
                        htmlFor="email"
                        style={{
                          fontSize: "12px",
                          color: "grey",
                          // fontWeight: 700,
                        }}
                      >
                        {" "}
                        {t("Schedule Type	")}
                      </label>
                      <input
                        value={
                          selectedDateData?.schedule_type
                            ? selectedDateData.schedule_type
                              .charAt(0)
                              .toUpperCase() +
                            selectedDateData.schedule_type.slice(1)
                            : ""
                        }
                        type="text"
                        className="form-control "
                        id="name"
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-5 col-sm-12 payment-card-right">
                  {/* <Payl8rCalculator price={totalPriceAndFee}/> */}
                  <Card.Body className="">
                    <div className="d-flex">
                      <div className="category-container">
                        {courseInfo?.courseDetail?.firm_course_categories &&
                          courseInfo?.courseDetail?.firm_course_categories.map(
                            (item, index) => {
                              return (
                                <span key={index} className="category-tag">
                                  {/* {course.course_category.lang_en} */}
                                  {languageData?.currentLanguage === "en" &&
                                    item.course_categories[0]?.lang_en}
                                  {/* {languageData?.currentLanguage === "pl" &&
                                    item.course_categories[0]?.lang_pl}
                                  {languageData?.currentLanguage === "ro" &&
                                    item.course_categories[0]?.lang_roa} */}
                                </span>
                              );
                            }
                          )}
                      </div>
                    </div>
                    <div style={{ height: '36px', overflow: 'hidden' }}>
                      <h5 className="my-2 mx-3">
                        {/* {courseInfo?.courseDetail?.title_en?.slice(0, 20) + ".."} */}
                        {languageData?.currentLanguage === "en" &&
                          courseInfo?.courseDetail?.title_en?.slice(0, 20) +
                          ".."}
                        {languageData?.currentLanguage === "pl" &&
                          courseInfo?.courseDetail?.title_pl?.slice(0, 20) +
                          ".."}
                        {languageData?.currentLanguage === "ro" &&
                          courseInfo?.courseDetail?.title_roa?.slice(0, 20) +
                          ".."}
                      </h5>
                    </div>
                    <Row className="mt-3 mx-1 d-flex align-items-center">
                      <div className="payment-pltr-box">
                        <span
                          className="me-2"
                          style={{ fontSize: "14px" }}
                        >
                          {t("The Course is Designed:")}
                          <span style={{ color: "blue", marginLeft: "5px", fontSize: "14px", fontWeight: "bold" }}>
                            {courseInfo?.courseDetail?.user_account?.firmName}
                          </span>
                        </span>
                      </div>
                    </Row>

                    <div style={{ height: '200px', overflow: 'hidden' }}>
                      <hr className="border-top custom-separator-line" />
                      <Row>
                        <Col>
                          <div className="payment-breakup-col1">
                            {" "}
                            {t("Accreditation")}
                          </div>
                        </Col>
                        <Col>
                          <div className="payment-breakup-col2">
                            {" "}
                            {/* {courseInfo?.courseDetail?.accrediation?.lang_en} */}
                            <TruncateString
                              str={
                                courseInfo?.courseDetail?.accrediation?.lang_en
                              }
                              maxLength={12}
                            />
                            {/* {languageData?.currentLanguage === "en" &&
                            courseInfo?.courseDetail?.accrediation?.lang_en} */}
                            {/* {languageData?.currentLanguage === "pl" &&
                            courseInfo?.courseDetail?.accrediation?.lang_pl}
                          {languageData?.currentLanguage === "ro" &&
                            courseInfo?.courseDetail?.accrediation?.lang_roa} */}
                          </div>
                        </Col>
                      </Row>
                      <hr className="border-top custom-separator-line-dotted" />
                      {/* <Row>
                        <Col>
                          <div className="payment-breakup-col1">
                            {" "}
                            {t("Lessons")}
                          </div>
                        </Col>
                        <Col>
                          <div className="payment-breakup-col2">
                            {" "}
                            {courseInfo?.courseDetail?.no_of_lessons}
                          </div>
                        </Col>
                      </Row>
                      <hr className="border-top custom-separator-line-dotted " />
                      <Row>
                        <Col>
                          <div className="payment-breakup-col1">
                            {" "}
                            {t("Hours")}
                          </div>
                        </Col>
                        <Col>
                          <div className="payment-breakup-col2">
                            {" "}
                            {courseInfo?.courseDetail?.no_of_hours}
                          </div>
                        </Col>
                      </Row>
                      <hr className="border-top custom-separator-line-dotted " /> */}
                    </div>



                    <Row>
                      <Button className="payments-dropdown-btn">
                        {/* {t("Payments")}
                        <span>
                          <span className="" style={{ fontWeight: 700 }}>
                            $
                            {(
                              parseInt(totalPriceAndFee) /
                              parseInt(
                                courseInfo?.courseDetail?.payment_options
                              )
                            ).toFixed(2)}
                          </span>
                          <span style={{ fontWeight: 700 }}>
                            {" "}
                            x {courseInfo?.courseDetail?.payment_options}
                          </span>{" "}

                          <svg
                            width="12"
                            height="7"
                            viewBox="0 0 12 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 1L6 6L11 1"
                              stroke="#09B970"
                              stroke-width="1.3"
                              stroke-linecap="round"
                            />
                          </svg>
                        </span> */}
                      </Button>
                    </Row>
                    <Row>
                      <Col>
                        <div
                          className="payment-breakup-col1 "
                          style={{
                            // fontWeight: 700,
                            fontSize: "16px",
                            color: "black",
                          }}
                        >
                          {" "}
                          {t("Course Price")}
                        </div>
                      </Col>
                      <Col>
                        <div
                          className="payment-breakup-col2"
                          style={{
                            // fontWeight: 700,
                            fontSize: "16px",
                            color: "black",
                          }}
                        >
                          {" "}
                          £{totalPriceAndFee}
                        </div>
                      </Col>
                    </Row>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginLeft: "10px", alignItems: "flex-start" }}>
                      <div className="terms-and-conditions mt-4">
                        <label>
                          <input
                            className="checkbox-custom"
                            type="checkbox"
                            checked={checked}
                            onChange={handleCheckboxChange}
                          />
                          {t("I have read and accept")}
                          <span>{t("Terms and Conditions")}</span>
                        </label>
                      </div>
                    
                      <div className="terms-and-conditions mt-2 mb-2">
                        <label>
                          <input
                            className="checkbox-custom"
                            type="checkbox"
                            checked={checked2}
                            onChange={handleCheckboxChange1}
                          />
                          {t("I agree to the ")}
                          <span>{t("Privacy Policy")}</span>
                        </label>
                      </div>
                    </div>

                    <Row>
                      <Col className="text-end">
                        <div className="d-flex align-items-center justify-content-center mt-2">
                          <div className="me-2"></div>
                          {checked && !loader && checked2 &&
                            (selectedLocation || selectedCourseDate) &&
                            formData?.address &&
                            formData?.city &&
                            formData?.pesel_number ? (
                            <div style={{ width: "100%" }}>
                              <form
                                target="payl8r"
                                action="https://payl8r.com/process"
                                method="post"
                              >
                                <input
                                  type="text"
                                  name="data"
                                  value={
                                    responseData?.data
                                      ? responseData?.data?.data
                                      : ""
                                  }
                                  hidden="true"
                                />
                                <input
                                  type="text"
                                  name="item_data[]"
                                  value={
                                    responseData?.data?.item_data
                                      ? responseData?.data?.item_data
                                      : ""
                                  }
                                  hidden="true"
                                />
                                <input
                                  type="text"
                                  name="rid"
                                  value={responseData?.data?.rid}
                                  hidden="true"
                                />        
                                {/* <input type="submit" />  */}
                                <div style={{ width: "100%" }}>


                                  <Button
                                    className="w-100 btn-lg px-4"
                                    variant="success"
                                    style={{
                                      background: "rgba(4, 201, 119, 1)",
                                      padding: "10px",
                                      fontSize: "18px",
                                      borderColor: "#04c977",
                                    }}
                                    onClick={saveAddressData}
                                    type="submit"
                                  >
                                    {t("Continue to Payment")}
                                  </Button>
                                </div>
                              </form>
                            </div>
                          ) : (
                            <div style={{ width: "100%" }}>
                              <Button
                                className="w-100 btn-lg px-4"
                                variant="success"
                                style={{
                                  background: "rgba(4, 201, 119, 1)",
                                  padding: "10px",
                                  fontSize: "18px",
                                  borderColor: "#04c977",
                                }}
                                onClick={() => toastForCheckBox()}
                              >
                                {loader ? (
                                  <span style={{ display: "flex", justifyContent: "center", alignItems: "center" }} > <Spinner /> Continue to Payment</span>
                                ) : (
                                  "Continue to Payment"
                                )}
                              </Button>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>

                    {/* <div
                        style={{
                          width: "98",
                          marginTop: "50px",
                          marginLeft: "11px",
                        }}
                      >
                        <Button
                          className="w-100 btn-lg px-4 "
                          variant="success"
                          style={{
                            background: "rgba(4, 201, 119, 1)",
                            padding: "10px",
                            fontSize: "18px",
                            borderColor: "#04c977",
                          }}
                          onClick={saveUserInfo}
                        >
                          {t("Save Your Information")}
                        </Button>
                      </div> */}
                  </Card.Body>
                </div>

                <div className="payment-detal-patl">
                  <Payl8rCalculator price={totalPriceAndFee} />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default Payment;
