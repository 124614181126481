import { baseUrl } from "../config";
export const masterAuth = async (data) => {
  try {
    const response = await fetch(`${baseUrl}/master/access`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    return error;
  }
};
